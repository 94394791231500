
import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class DoConversationApiService extends BaseService {

    constructor(public apiService: ApiService) {
        super(apiService, 'DoConversationApi');
    }

    public filter(model: any) {
        return this.apiService.post(`${this.apiName}/Filter`, model);
    }
    public comment(model: any) {
        return this.apiService.post(`${this.apiName}/Comment`, model);
    }
    public sendNotification(model: any) {
        return this.apiService.post(`${this.apiName}/SendNotification`, model);
    }
}
