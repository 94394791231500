import { Injectable } from "@angular/core";

import { ApiService } from "../../core/services";
import { BaseService } from "../../core/services/base.service";

@Injectable()
export class DoReceiptApiService extends BaseService {
  constructor(public apiService: ApiService) {
    super(apiService, "DoReceiptApi");
  }

  public getFeeForClass(classId: string, schoolYearId: string, month: string) {
    return this.apiService.get(`${this.apiName}/GetFeeForClass?classId=${classId}&schoolYearId=${schoolYearId}&month=${month}`);
  }
  public confirmPaid(model: any) {
    return this.apiService.post(`${this.apiName}/ConfirmPaid`, model);
  }
  public insertCourseReceipt(model: any) {
    return this.apiService.post(`${this.apiName}/InsertCourseReceipt`, model);
  }
  public reportReceiptChildUnPaid(model: any) {
    return this.apiService.post(`${this.apiName}/ReportReceiptChildUnPaid`, model);
  }
  public remindPaymentNotification(model: any) {
    return this.apiService.post(`${this.apiName}/remindPaymentNotification`, model);
  }
  public getPriceNeedPaid(receiptId: string) {
    return this.apiService.get(`${this.apiName}/GetPriceNeedPaid?receiptId=${receiptId}`);
  }
  public filter(model: any) {
    return this.apiService.post(`${this.apiName}/Filter`, model);
  }
  public exportReportReceiptChildUnPaid(model: any) {
    return this.apiService.post(`${this.apiName}/ExportReportReceiptChildUnPaid`, model);
  }
}
