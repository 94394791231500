
import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class DoClassOtApiService extends BaseService {

    constructor(public apiService: ApiService) {
        super(apiService, 'DoClassOtApi');
    }

    getTeacherAvailableInClassOt() {
        return this.apiService.get(`${this.apiName}/GetTeacherAvailableInClassOt`);
    }

    reportCheckinLate(model: any) {
        return this.apiService.post(`${this.apiName}/ReportCheckinLate`, model);
    }
}
