
import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class ImportApiService extends BaseService {
    constructor(public apiService: ApiService) { super(apiService, 'ImportApi'); }
    public importChild(data: any) { return this.apiService.post(`${this.apiName}/ImportChild`, data); }
    public importGeneral01(data: any) { return this.apiService.post(`${this.apiName}/ImportGeneral01`, data); }
    public importHeathIndex(data: any) { return this.apiService.post(`${this.apiName}/ImportHeathIndex`, data); }
    public importChildInClass(data: any) { return this.apiService.post(`${this.apiName}/ImportChildInClass`, data); }
    public importUser(data: any) { return this.apiService.post(`${this.apiName}/ImportUser`, data); }
    public importTeacherOfClass(data: any) { return this.apiService.post(`${this.apiName}/ImportTeacherOfClass`, data); }
}
