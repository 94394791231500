import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class DoCourseRegistrationApiService extends BaseService {

    constructor(public apiService: ApiService) {
        super(apiService, 'DoCourseRegistrationApi');
    }

    transferCourse(model: any) {
        return this.apiService.post(`${this.apiName}/TransferCourse`, model);
    }
}
