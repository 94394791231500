
import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class DoCarriageWayApiService extends BaseService {

    constructor(public apiService: ApiService) {
        super(apiService, 'DoCarriageWayApi');
    }

    getDrivers(id: string) {
        return this.apiService.get(`${this.apiName}/GetDrivers/${id}`);
    }

    getBabyCares(id: string) {
        return this.apiService.get(`${this.apiName}/GetBabyCares/${id}`);
    }

    getLocationByCarriageWay(id: string) {
        return this.apiService.get(`${this.apiName}/GetLocationByCarriageWay/${id}`)
    }

    insertCarriageWayLocation(model: any) {
        return this.apiService.post(`${this.apiName}/InsertCarriageWayLocation`, model);
    }

    insertCarriageWayLocations() {
        return this.apiService.get(`${this.apiName}/InsertCarriageWayLocations`);
    }

    updateCarriageWayLocation(model: any) {
        return this.apiService.post(`${this.apiName}/UpdateCarriageWayLocation`, model);
    }

    deleteCarriageWayLocation(model: any) {
        return this.apiService.post(`${this.apiName}/DeleteCarriageWayLocation`, model);
    }
    getChildCheckins(id: string, date: any) {
        return this.apiService.get(`${this.apiName}/GetChildCheckins?busTripId=${id}&date=${date}`);
    }
    getListCheckIn(carriageWayId: string, date: string) {
        return this.apiService.get(`${this.apiName}/GetListCheckIn?carriageWayId=${carriageWayId}&date=${date}`);
    }

    checkInOut(models: any) {
        return this.apiService.post(`${this.apiName}/CheckInOut`, models);
    }

    createBusTrip(models: any) {
        return this.apiService.post(`${this.apiName}/CreateBusTrip`, models);
    }

    endBusTrip(models: any) {
        return this.apiService.post(`${this.apiName}/EndBusTrip`, models);
    }

    AddProblem(models: any) {
        return this.apiService.post(`${this.apiName}/AddProblem`, models);
    }

    getByBus(id: string) {
        return this.apiService.get(`${this.apiName}/GetByBus/${id}`);
    }

    updateChildInCarriageWay(models: any) {
        return this.apiService.post(`${this.apiName}/UpdateChildInCarriageWay`, models);
    }

    getBusTripByCarriageWay(carriageWayId: string, date: string) {
        return this.apiService.get(`${this.apiName}/GetBusTripByCarriageWay?carriageWayId=${carriageWayId}&date=${date}`);
    }

    cloneForCheckout(id: string) {
        return this.apiService.get(`${this.apiName}/CloneForCheckout/${id}`);
    }

    cloneForCheckouts(model: any) {
        return this.apiService.post(`${this.apiName}/CloneForCheckout`, model);
    }

    cloneAllForCheckout() {
        return this.apiService.get(`${this.apiName}/CloneAllForCheckout`);
    }
    getNoRestrictedByBus(id: string) {
        return this.apiService.get(`${this.apiName}/GetNoRestrictedByBus/${id}`);
    }
}
