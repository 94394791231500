import { Injectable } from '@angular/core';

@Injectable()
export class AppSettingService {

    private appSetting: any = {};

    constructor() {

    }

}