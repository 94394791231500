
import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class DoClassScheduleApiService extends BaseService {

    constructor(public apiService: ApiService) {
        super(apiService, 'DoClassScheduleApi');
    }

    getByClass(classId:string, month: number) {
        return this.apiService.get(`${this.apiName}/GetByClass?ClassId=${classId}&month=${month}`);
    }
}
