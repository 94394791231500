import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { TranslateModule } from "@ngx-translate/core";
import { StorageServiceModule } from "angular-webstorage-service";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { GrpcCoreModule } from "@ngx-grpc/core";
import { GrpcWebClientModule } from "@ngx-grpc/grpc-web-client";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./core";
import { InfrastructureModule } from "./infrastructure/infrastructure.module";
import { ConfigsModule } from "./configs/configs.module";
import { environment } from "environments/environment";
import { NinoMaterialModule } from "./material.module";
import { MaximizeModule } from "@infrastructure/modules/maximize.module";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    StorageServiceModule,
    CoreModule,
    InfrastructureModule,
    ConfigsModule,
    LoadingBarRouterModule,
    LoadingBarHttpClientModule,
    GrpcCoreModule.forRoot(),
    GrpcWebClientModule.forRoot({
      settings: { host: environment.grpcUrl }
    }),
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
    NinoMaterialModule,
    MaximizeModule
  ],
  providers: [MaximizeModule],
  bootstrap: [AppComponent]
})
export class AppModule {}
