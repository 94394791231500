import { Injectable } from "@angular/core";

import { ApiService } from "../../core/services";
import { BaseService } from "../../core/services/base.service";

@Injectable()
export class DoInvoiceOfApiService extends BaseService {
  constructor(public apiService: ApiService) {
    super(apiService, "DoInvoiceOfApi");
  }
  
  lockEdit(id: string) {
    return this.apiService.get(`${this.apiName}/LockEdit/${id}`)
  }
}
