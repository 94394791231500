
import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class DoMobileAppModuleApiService extends BaseService {

    constructor(public apiService: ApiService) {
        super(apiService, 'DoMobileAppModuleApi');
    }

    filter(model: any) {
        return this.apiService.post(`${this.apiName}/Filter`, model);
    }
}
