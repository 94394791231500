export interface ModuleModel {
    id: string,
    code: string,
    name: string
}

export class ModuleModel {
    constructor() {

    }
}