
import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class DoLearningOutcomeApiService extends BaseService {

    constructor(public apiService: ApiService) {
        super(apiService, 'DoLearningOutcomeApi');
    }
    public sendNotification(model: any) {
        return this.apiService.post(`${this.apiName}/SendNotification`, model);
    }

    public getGridLearningOutcome(model: any) {
        return this.apiService.post(`${this.apiName}/GetGridLearningOutcome`, model);
    }
}
