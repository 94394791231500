import { NgModule } from '@angular/core';

import { AppSettingService } from './app-setting.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [AppSettingService],
})
export class ConfigsModule { }
