
import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class DoBusApiService extends BaseService {

  constructor(public apiService: ApiService) {
    super(apiService, 'DoBusApi');
  }

  getBusTracking(data: any) {
    return this.apiService.post(`${this.apiName}/GetBusTracking`, data);
  }

  getBusTrackingAll(data: any) {
    return this.apiService.post(`${this.apiName}/GetBusTrackingAll`, data);
  }

  filterBusTripCheckin(data: any) {
    return this.apiService.post(`${this.apiName}/FilterBusTripCheckin`, data);
  }

  public ReportProblem(data: any) {
    return this.apiService.post(`${this.apiName}/ReportProblem`, data);
  }

  public getDashboardData() {
    return this.apiService.get(`${this.apiName}/GetDashboardData`);
  }

  public getDashboardCarriageWayInfo(id: string) {
    return this.apiService.get(`${this.apiName}/GetDashboardCarriageWayInfo//${id}`);
  }

  public importDriver(data: any) {
    return this.apiService.post(`${this.apiName}/ImportDriver`, data);
  }

  public importDriverToBus(data: any) {
    return this.apiService.post(`${this.apiName}/ImportDriverToBus`, data);
  }
  public getAllNoRestricted() {
    return this.apiService.get(`${this.apiName}/GetAllNoRestricted`);
  }
  getBusNameByBustripId(id: string) {
    return this.apiService.get(`${this.apiName}/GetBusNameByBustripId?busTripId=${id}`)
  }
}
