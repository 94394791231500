
import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class DoServiceApiService extends BaseService {

    constructor(public apiService: ApiService) {
        super(apiService, 'DoServiceApi');
    }

    reportRegisterService(model: any) {
        return this.apiService.post(`${this.apiName}/ReportRegisterService`, model);
    }

    updateRegisterService(model: any) {
        return this.apiService.post(`${this.apiName}/UpdateRegisterService`, model);
    }

    deleteRegisterService(model: any) {
        return this.apiService.post(`${this.apiName}/DeleteRegisterService`, model);
    }

    getRegisterServiceById(id: string) {
        return this.apiService.get(`${this.apiName}/GetRegisterServiceById/${id}`)
    }

    getByCurrentSchool() {
        return this.apiService.get(`${this.apiName}/GetByCurrentSchool`)
    }

}
