
import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class DoFeeApiService extends BaseService {

    constructor(public apiService: ApiService) {
        super(apiService, 'DoFeeApi');
    }

    public addFee(model: any) {
        return this.apiService.post(`${this.apiName}/AddFee`, model);
    }

    public updateFee(model: any) {
        return this.apiService.post(`${this.apiName}/UpdateFee`, model);
    }

    getRequiredFee(isRequired: boolean) {
        return this.apiService.get(`${this.apiName}/GetRequiredFee/?isRequired=${isRequired}`)
    }

    getAllFee() {
        return this.apiService.get(`${this.apiName}/GetAllFee`)
    }
}
