import { NgModule } from '@angular/core';
import { ToastrModule } from 'ng6-toastr-notifications';
import { TranslateModule } from '@ngx-translate/core';


import { AuthService } from './auth/auth.service';
import { ModuleViewManagementService } from './services/module-view-management.service';
import * as apiServices from './api-services';



@NgModule({
    imports: [
        TranslateModule,
        ToastrModule.forRoot()
    ],
    exports: [],
    declarations: [],
    providers: [
        AuthService,
        ModuleViewManagementService,

        apiServices.CoreConfigApiService,
        apiServices.CoreEnumApiService,
        apiServices.CoreMenuApiService,
        apiServices.CoreModuleApiService,
        apiServices.CorePermissionApiService,
        apiServices.CoreTableApiServcice,
        apiServices.CoreUserApiService,
        apiServices.CoreViewApiService,
        apiServices.FileManagerApiService,

        apiServices.DoChildApiService,
        apiServices.DoClassApiService,
        apiServices.DoClassGroupApiService,
        apiServices.DoLocationApiService,
        apiServices.DoParentApiService,
        apiServices.DoSchoolApiService,
        apiServices.DoMenuFoodApiService,
        apiServices.DoTeacherApiService,
        apiServices.DoChildrenConnectToParentApiService,
        apiServices.DoSchoolSubjectApiService,
        apiServices.DoExtracurricularActivityApiService,
        apiServices.DoClassScheduleApiService,
        apiServices.DoBlogApiService,
        apiServices.DoBlogCategoryApiService,
        apiServices.DoChildrenOffApiService,
        apiServices.DoAlbumApiService,
        apiServices.DoClassYearApiService,
        apiServices.DoFeeTypeApiService,
        apiServices.DoBusApiService,
        apiServices.DoCarriageWayApiService,
        apiServices.DoSchoolYearApiService,
        apiServices.DoPaymentApiService,
        apiServices.DoReceiptApiService,
        apiServices.DoFeeApiService,
        apiServices.DoPaymentTermApiService,
        apiServices.DoUserRatingApiService,
        apiServices.DoClassFeeApiService,
        apiServices.DoChildOptionalFeeApiService,
        apiServices.DoMessageApiService,
        apiServices.DoSchoolHolidayApiService,
        apiServices.DoSchoolStartEndDayInWeekApiService,
        apiServices.DoProblemTemplateApiService,
        apiServices.DoCommentApiService,
        apiServices.DoCommentTemplateApiService,
        apiServices.DoFeeLateApiService,
        apiServices.DoClassOtApiService,
        apiServices.DoChildrenDiscountApiService,
        apiServices.DoServiceApiService,
        apiServices.DoProgramApiService,
        apiServices.DoEventApiService,
        apiServices.DoCourseApiService,
        apiServices.DoChildTranscriptApiService,
        apiServices.DoChildrenInCourseApiService,
        apiServices.DoCcscheduleApiService,
        apiServices.DoClassRoomApiService,
        apiServices.DoTranscriptApiService,
        apiServices.DoPaymentTypeApiService,
        apiServices.DoFeedbackApiService,
        apiServices.DoCarriageWayChildrenApiService,
        apiServices.DoCarriageWayLocationRegisterApiService,
        apiServices.ImportApiService,
        apiServices.DoHealthIndexOfChildrenApiService,
        apiServices.DoFoodApiService,
        apiServices.DoBusLocationApiService,
        apiServices.DoRequestFoodApiService,
        apiServices.DoInvoiceApiService,
        apiServices.DoCourseRegistrationApiService,
        apiServices.DoUserManualApiService,
        apiServices.DoConversationApiService,
        apiServices.DoInvoiceOfApiService,
        apiServices.DoRequestFoodMealApiService,
        apiServices.ReportApiService,
        apiServices.DoCommentSheetApiService,
        apiServices.DoReportEndDayApiService,
        apiServices.DoLocationChildRegistrationApiService,
        apiServices.DoInvoiceOfTypeApiService,
        apiServices.DoLearningOutcomeQuestionApiService,
        apiServices.DoLearningOutcomeTemplateApiService,
        apiServices.DoLearningOutcomeApiService,
        apiServices.DoSemesterApiService,
        apiServices.DoMobileAppIdApiService,
        apiServices.DoMobileAppModuleApiService,
        apiServices.DoMobileAppReleaseApiService,
        apiServices.DoMobileAppWlusingNewModuleApiService,
    ],
})
export class InfrastructureModule { }
