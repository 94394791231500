// //Dev Environment
// export const environment = {
//   production: false,
//   serverUrl: '/api/',
//   defaultLanguage: 'vi-VN',
//   supportedLanguages: [
//     'vi-VN', 'en-US'
//   ],
//   grpcUrl: 'https://api2019v1nino.ninoapp.vn:5002',
//   // grpcUrl: 'http://localhost:5002',
//   scriptStores: [
//     { name: 'osm', src: 'assets/js-libs/osm/bundle.js' },
//   ]
// };


//Test Environment
export const environment = {
  production: false,
    serverUrl: 'https://v2api2019.vitgym.com/api/',
  defaultLanguage: 'vi-VN',
  supportedLanguages: [
    'vi-VN','en-US'
  ],
  grpcUrl: 'https://api2019v1nino.ninoapp.vn:5002',
  // grpcUrl: 'http://localhost:5002',
  scriptStores: [
    { name: 'osm', src: 'assets/js-libs/osm/bundle.js' },
  ]
};


// //Prod Environment
// export const environment = {
//  production: false,
//    serverUrl: 'https://api2019v1nino.ninoapp.vn/api/',
//  defaultLanguage: 'vi-VN',
//  supportedLanguages: [
//    'vi-VN','en-US'
//  ],
//  grpcUrl: 'https://api2019v1nino.ninoapp.vn:5002',
//  // grpcUrl: 'http://localhost:5002',
//  scriptStores: [
//    { name: 'osm', src: 'assets/js-libs/osm/bundle.js' },
//  ]
// };
