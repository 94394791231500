
import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class DoLocationChildRegistrationApiService extends BaseService {

    constructor(public apiService: ApiService) {
        super(apiService, 'DoLocationChildRegistrationApi');
    }
    GetByChildId(childId : string) {
        return this.apiService.get(`${this.apiName}/GetByChildId?childId=${childId}`);
    }
    GetLatestChangedByChildId(childId : string) {
        return this.apiService.get(`${this.apiName}/GetLatestChangedByChildId?childId=${childId}`);
    }
    GetToChangeFromByChildId(childId : string) {
        return this.apiService.get(`${this.apiName}/GetToChangeFromByChildId?childId=${childId}`);
    }
}
