
import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class DoTeacherApiService extends BaseService {

    constructor(public apiService: ApiService) {
        super(apiService, 'DoTeacherApi');
    }

    getByClass(classId:string) {
        return this.apiService.get(`${this.apiName}/GetByClass?ClassId=${classId}`);
    }
}
