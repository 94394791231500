
import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class DoLocationApiService extends BaseService {

    constructor(public apiService: ApiService) {
        super(apiService, 'DoLocationApi');
    }

    getProvinces() {
        return this.apiService.get(`${this.apiName}/GetProvinces`);
    }

    getDistricts(provinceId: string) {
        return this.apiService.get(`${this.apiName}/GetDistricts?provinceId=${provinceId}`);
    }

    getWards(districtId: string) {
        return this.apiService.get(`${this.apiName}/GetWards?districtId=${districtId}`);
    }
}
