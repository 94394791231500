
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = environment.scriptStores;

declare var document: any;

@Injectable()
export class DynamicScriptLoaderService {

  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script, false)));
    return Promise.all(promises);
  }

  loadScript(name: string, forceReload: boolean) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded || forceReload === true) {
        //load script
        const currentDate = new Date();
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `${this.scripts[name].src}?tick=${currentDate.getTime()}`;
        script.id = name
        if (script.readyState) {  //IE
            script.onreadystatechange = () => {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    this.scripts[name].loaded = true;
                    resolve({script: name, loaded: true, status: 'Loaded'});
                }
            };
        } else {  //Others
            script.onload = () => {
                this.scripts[name].loaded = true;
                resolve({script: name, loaded: true, status: 'Loaded'});
            };
        }
        script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }

  unload(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.unLoadScript(script)));
    return Promise.all(promises);
  }

  unLoadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (this.scripts[name].loaded) {
        //Unload script
        let script = document.getElementById(name);
        document.getElementsByTagName('head')[0].removeChild(script);
        resolve({script: name, loaded: false, status: 'Already unload'});
      } else {
        resolve({ script: name, loaded: true, status: 'Script does not exist' });
      }
    });
  }

  unLoadOsmScript(name: string) {
    return new Promise((resolve, reject) => {
      if (this.scripts[name].loaded) {
        //Unload script
        let osmScripts = document.querySelectorAll('[id^=_l_geocoder_]');
        for (let i = 0; i < osmScripts.length; i++) {
          document.getElementsByTagName('head')[0].removeChild(osmScripts[i]); 
        }
        resolve({script: name, loaded: false, status: 'Already unload'});
      } else {
        resolve({ script: name, loaded: true, status: 'Script does not exist' });
      }
    });
  }
  reLoadOsmScript(name: string) {
    return new Promise((resolve, reject) => {
      if (this.scripts[name].loaded) {
        //Unload script
        let script = document.getElementById(name);
        let osmScripts = document.querySelectorAll('[id^=_l_geocoder_]');
        document.getElementsByTagName('head')[0].removeChild(script);
        for (let i = 0; i < osmScripts.length; i++) {
          document.getElementsByTagName('head')[0].removeChild(osmScripts[i]); 
        }
        resolve({script: name, loaded: false, status: 'Already unload'});
      } else if(!this.scripts[name].loaded) {
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        script.id = name
        if (script.readyState) {  //IE
            script.onreadystatechange = () => {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    this.scripts[name].loaded = true;
                    resolve({script: name, loaded: true, status: 'Loaded'});
                }
            };
        } else {  //Others
            script.onload = () => {
                this.scripts[name].loaded = true;
                resolve({script: name, loaded: true, status: 'Loaded'});
            };
        }
        script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
        document.getElementsByTagName('head')[0].appendChild(script);
      }else{
        resolve({ script: name, loaded: true, status: 'Script does not exist' });
      }
    });
  }
}