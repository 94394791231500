import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class DoClassYearApiService extends BaseService {

    constructor(public apiService: ApiService) {
        super(apiService, 'DoClassYearApi');
    }

    getChildAndTeacher(id: string) {
        return this.apiService.get(`${this.apiName}/GetChildAndTeacher?classYearId=${id}`);
    }

    getByClass(id: string) {
        return this.apiService.get(`${this.apiName}/GetByClass?classId=${id}`);
    }
    
    updateChildInClass(models: any[]) {
        return this.apiService.post(`${this.apiName}/UpdateChildInClass`, models);
    }

    validExit(fromYear: string) {
        return this.apiService.post(`${this.apiName}/ValidExit`, fromYear);
    }
}
