export interface GridViewSortStatusModel{
    columnId: string,
    expression: string,
    class:string
}

export class GridViewSortStatusModel{
    constructor(){

    }
}