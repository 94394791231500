import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class CoreUserApiService extends BaseService {

    constructor(public apiService: ApiService) {
        super(apiService, 'CoreUserApi');
    }

    EditProfile(profileModel) {
        return this.apiService.post(`${this.apiName}/EditProfile`, profileModel);
    }

    getByRoleCode(roleCode: string) {
        return this.apiService.get(`${this.apiName}/GetByRoleCode?roleCode=${roleCode}`);
    }

    getByCurrentSchool() {
        return this.apiService.get(`${this.apiName}/GetByCurrentSchool`);
    }

    getByPhone(phone: string) {
        return this.apiService.get(`${this.apiName}/GetByPhone?phone=${phone}`);
    }
}
