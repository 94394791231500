export interface ViewModel {
    id: string,
    code: string,
    name: string
}

export class ViewModel {
    constructor() {

    }
}