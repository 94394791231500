import { Injectable } from "@angular/core";

import { ApiService } from "../../core/services";
import { BaseService } from "../../core/services/base.service";
import { environment } from "environments/environment";

@Injectable()
export class DoClassApiService extends BaseService {
  constructor(public apiService: ApiService) {
    super(apiService, "DoClassApi");
  }

  public importFee(data: any) {
    return this.apiService.post(`${this.apiName}/ImportFee`, data);
  }

  public getChildFees(classId: string) {
    return this.apiService.get(
      `${this.apiName}/GetChildFees?ClassId=${classId}`
    );
  }

  public confirmPaid(id: string) {
    return this.apiService.get(`${this.apiName}/confirmPaid/${id}`);
  }

  public sendNotification(data: any) {
    return this.apiService.post(`${this.apiName}/SendNotification`, data);
  }

  public getListCheckIn(classId: string, date: string) {
    return this.apiService.get(
      `${this.apiName}/GetListCheckIn?classId=${classId}&date=${date}`
    );
  }

  public checkInOut(models: any) {
    return this.apiService.post(`${this.apiName}/CheckInOut`, models);
  }

  public updateClassBySchoolYear(models: any) {
    return this.apiService.post(
      `${this.apiName}/UpdateClassBySchoolYear`,
      models
    );
  }

  getByClassGroup(id: string) {
    return this.apiService.get(
      `${this.apiName}/GetByClassGroup?ClassGroupsId=${id}`
    );
  }

  public filterCheckIn(models: any) {
    return this.apiService.post(`${this.apiName}/FilterCheckIn`, models);
  }
  public exportReportClass(models: any) {
    return this.apiService.post(`${this.apiName}/ExportReportClass`, models);
  }

  public filter(data: any) {
    return this.apiService.post(`${this.apiName}/Filter`, data);
  }

  public reportCheckinClass(data: any) {
    return this.apiService.post(`${this.apiName}/ReportCheckinClass`, data);
  }

  public getClassCheckinById(id: string) {
    return this.apiService.get(`${this.apiName}/GetClassCheckinById/${id}`);
  }

  public updateCheckin(data: any) {
    return this.apiService.post(`${this.apiName}/UpdateCheckin`, data);
  }

  public deleteCheckin(data: any) {
    return this.apiService.post(`${this.apiName}/DeleteCheckin`, data);
  }

  public getByIdAndInfoCourse(id: string) {
    return this.apiService.get(`${this.apiName}/GetByIdAndInfoCourse/${id}`);
  }

  public getClassByCourse(id: string) {
    return this.apiService.get(`${this.apiName}/GetClassByCourse?courseId=${id}`);
  }

  public completeCourse(childrenInClass: string[]) {
    return this.apiService.post(`${this.apiName}/CompleteCourse`, childrenInClass);
  }

  public getHistoryClassOfChild(id: string[]) {
    return this.apiService.get(`${this.apiName}/GetHistoryClassOfChild?childId=${id}`);
  }

  public processChildrenInClass(data: any) {
    return this.apiService.post(`${this.apiName}/ProcessChildrenInClass`, data);
  }
  public completeCourseByClass(classId: string, ccId: string) {
    return this.apiService.get(`${this.apiName}/CompleteCourseByClass?classId=${classId}&ccId=${ccId}`);
  }
  public enable(data: any) {
    return this.apiService.post(`${this.apiName}/Enable`, data);
  }
  public disable(data: any) {
    return this.apiService.post(`${this.apiName}/Disable`, data);
  }
}
