
import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class DoExtracurricularActivityApiService extends BaseService {

    constructor(public apiService: ApiService) {
        super(apiService, 'DoExtracurricularActivityApi');
    }

    getByYear(from: number, to: number, classGroupId: string) {
        return this.apiService.get(`${this.apiName}/GetByYear?fromYear=${from}&toYear=${to}&classGroupId=${classGroupId}`);
    }
}
