
import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class DoChildTranscriptApiService extends BaseService {

    constructor(public apiService: ApiService) {
        super(apiService, 'DoChildTranscripteApi');
    }

    getTranscripts() {
        return this.apiService.get(`${this.apiName}/GetTranscripts`);
    }

    getChildByClass(classId: any){
        return this.apiService.get(`${this.apiName}/GetChildByClass?classId=${classId}`);
    }
}
