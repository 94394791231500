
import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class DoClassFeeApiService extends BaseService {

  constructor(public apiService: ApiService) {
    super(apiService, 'DoClassFeeApi');
  }

  public filterClassFee(data: any) {
    return this.apiService.post(`${this.apiName}/FilterClassFee`, data);
  }
  public insertFeeDetail(data: any) {
    return this.apiService.post(`${this.apiName}/InsertFeeDetail`, data);
  }
  public getByChild(id: string) {
    return this.apiService.get(`${this.apiName}/GetByChild/?childId=${id}`)
  }
}
