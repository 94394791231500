
import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class DoCcscheduleApiService extends BaseService {

    constructor(public apiService: ApiService) {
        super(apiService, 'DoCcscheduleApi');
    }

    getByClass(id: string) {
        return this.apiService.get(`${this.apiName}/GetByClass/?classId=${id}`)
    }

    updateCcscheduleDetail(model: any) {
        return this.apiService.post(`${this.apiName}/UpdateCcscheduleDetail`, model);
    }

    
    filterCcschedule(model: any) {
        return this.apiService.post(`${this.apiName}/FilterCcschedule`, model);
    }
}
