
import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class DoEventApiService extends BaseService {

    constructor(public apiService: ApiService) {
        super(apiService, 'DoEventApi');
    }
    getByIdFullDetail(id: string) {
        return this.apiService.get(`${this.apiName}/GetByIdFullDetail/${id}`)
    }
}
