
import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class DoCourseApiService extends BaseService {

    constructor(public apiService: ApiService) {
        super(apiService, 'DoCourseApi');
    }

    registerChildCourse(model: any) {
        return this.apiService.post(`${this.apiName}/RegisterChildCourse`, model);
    }

    filter(model: any) {
        return this.apiService.post(`${this.apiName}/Filter`, model);
    }

    getByClass(id: string) {
        return this.apiService.get(`${this.apiName}/GetByClass/?classId=${id}`)
    }

    reportDetailCourse(model: any) {
        return this.apiService.post(`${this.apiName}/ReportDetailCourse`, model);
    }
    reportChildCourseExp(model: any) {
        return this.apiService.post(`${this.apiName}/ReportChildCourseExp`, model);
    }
    reportReceiptCourseForSale(model: any) {
        return this.apiService.post(`${this.apiName}/ReportReceiptCourseForSale`, model);
    }
    reportChildCourseNoClass(model: any) {
        return this.apiService.post(`${this.apiName}/ReportChildCourseNoClass`, model);
    }
}
