
import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';



@Injectable()
export class DoChildApiService extends BaseService {

    constructor(public apiService: ApiService) {
        super(apiService, 'DoChildApi');
    }

    public getByCode(code: string) {
        return this.apiService.get(`${this.apiName}/GetChildByCode?code=${code}`);
    }

    public importChild(data: any) {
        return this.apiService.post(`${this.apiName}/ImportChild`, data);
    }

    public importGeneral01(data: any) {
        return this.apiService.post(`${this.apiName}/ImportGeneral01`, data);
    }

    public importHeathIndex(data: any) {
        return this.apiService.post(`${this.apiName}/ImportHeathIndex`, data);
    }

    public getAvailableInClass(classId: string) {
        return this.apiService.get(`${this.apiName}/GetAvailableInClass?ClassId=${classId}`);
    }

    public getAvailableInClassCourse(classId: string, courseId: string) {
        return this.apiService.get(`${this.apiName}/GetAvailableInClassCourse?ClassId=${classId}&CourseId=${courseId}`);
    }

    public getAvailableInCarriageway(data: any) {
        return this.apiService.post(`${this.apiName}/GetAvailableInCarriageway`, data);
    }

    public getAvailableInLocation(data: any) {
        return this.apiService.post(`${this.apiName}/GetAvailableInLocation`, data);
    }

    getByClass(classId: string) {
        return this.apiService.get(`${this.apiName}/GetByClass?ClassId=${classId}`);
    }

    getByCarriageWay(carriageWayId: string) {
        return this.apiService.get(`${this.apiName}/GetByCarriageWay?CarriageWayId=${carriageWayId}`);
    }

    updateChildInClass(data: any) {
        return this.apiService.post(`${this.apiName}/UpdateChildInClass`, data);
    }

    GetFees(data: any) {
        return this.apiService.post(`${this.apiName}/GetFees`, data);
    }

    Filter(data: any) {
        return this.apiService.post(`${this.apiName}/Filter`, data);
    }

    public exportReportChild(models: any) {
        return this.apiService.post(`${this.apiName}/ExportReportChild`, models);
    }

    public fiterChildInClassOt(models: any) {
        return this.apiService.post(`${this.apiName}/FiterChildInClassOt`, models);
    }

    public getChildByPhoneParent(models: any) {
        return this.apiService.post(`${this.apiName}/GetChildByPhoneParent`, models);
    }
    getCountChildData() {
        return this.apiService.get(`${this.apiName}/GetCountChildData`);
    }

    public reportChildStatusOff(models: any) {
        return this.apiService.post(`${this.apiName}/ReportChildStatusOff`, models);
    }
    public exportReportChildStatusOff(models: any) {
        return this.apiService.post(`${this.apiName}/ExportReportChildStatusOff`, models);
    }

    getAllByClass(classId: string) {
        return this.apiService.get(`${this.apiName}/GetAllByClass?ClassId=${classId}`);
    }
    getByClassCourse(ccId: string) {
        return this.apiService.get(`${this.apiName}/GetByClassCourse?ccId=${ccId}`);
    }
    getAllByClassAndSchoolYear(classId: string, schoolYearId: string) {
        return this.apiService.get(`${this.apiName}/GetAllByClassAndSchoolYear?classId=${classId}&schoolYearId=${schoolYearId}`);
    }

    getGridChild(filter: any) {
        return this.apiService.post(`${this.apiName}/GetGridChild`, filter);
    }
}
