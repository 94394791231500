
import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class DoCarriageWayLocationRegisterApiService extends BaseService {

    constructor(public apiService: ApiService) {
        super(apiService, 'DoCarriageWayLocationRegisterApi');
    }  
    verify(model: any) {
        return this.apiService.post(`${this.apiName}/Verify`, model);
    }
}
