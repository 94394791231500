
import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class DoChildOptionalFeeApiService extends BaseService {

    constructor(public apiService: ApiService) {
        super(apiService, 'DoChildOptionalFeeApi');
    }

    filterChildrenInClass(models: any) {
      return this.apiService.post(`${this.apiName}/FilterChildrenInClass`, models);
  }
  filterChildOptionalFee(models: any) {
    return this.apiService.post(`${this.apiName}/FilterChildOptionalFee`, models);
}
getFeeType() {
  return this.apiService.get(`${this.apiName}/GetFeeType`);
}

public insertChildOptionalFee(data: any) {
  return this.apiService.post(`${this.apiName}/InsertChildOptionalFee`,data);
}

}
