export class Constants {
    public static AppSettingsKey: string = 'appsettings';
    public static CredentialsKey: string = 'credentials';

    public static DataTypes: any = {
        Html: 'HTML',
        Checkbox: 'CHECKBOX',
        Number: 'NUMBER',
        UniqueIdentifier: 'UNIQUEIDENTIFIER',
        Select: 'SELECT',
        Email: 'EMAIL',
        Date: 'DATE',
        MultiSelect: 'MULTISELECT',
        Currency: 'CURRENCY',
        Text: 'TEXT',
        MultiText: 'MULTITEXT',
        Link: 'LINK',
        Image: 'IMAGE',
        DateTime: 'DATETIME',
        Time: 'TIME',
        DateRange: 'DATERANGE'
    };

    public static SortExpression: { Asc: string, Desc: string } = {
        Asc: 'ASC',
        Desc: 'DESC'
    };

    public static ResultStatus = {
        Success: 1,
        Error: 2,
        ValidateFail: 3
    };

    public static LoginType = {
        Email: 'dd5fe78a-2ee4-4e8c-a66c-c05025908955'
    };

    public static CurrencyMaskConfig = {
        align: "right",
        allowNegative: true,
        allowZero: true,
        decimal: ",",
        precision: 0,
        prefix: "",
        suffix: "đ",
        thousands: ",",
        nullable: true
    };

    public static EnumCode = {
        DataServiceType: 'DATA_SERVICE_TYPE',
        Region: 'REGION',
        CoursRegistrationStatus: 'COURSE_REGISTRATION_STATUS'
    };

    public static DefaultImage: string = 'https://via.placeholder.com/';

    public static EnumValueTypeConfig = {
        Link: '6e2ed2a9-66a0-4535-a4d1-0854c94ff895',
        BlogCategory: 'a82ad7aa-9962-479e-ae2d-47bd213aca43',
        SinglePage: '1238e5b0-60bf-425d-9d06-506733e8dfff',
        ProductType: '6f5639b8-e1ff-48cc-902b-5975be9eaac8'
    };

    public static SchoolModule = {
        NinoBus: 'c3334cee-f82a-49d0-8fd3-3a2985416c60',
        NinoSchool: '69a8ed05-dfd3-4e1a-a9ce-bfc152ace949',
        Both: 'f53601c2-4a3d-47e0-8fc4-5f5926fdff65',
        KCMC: '75e0fe7b-25b1-49ea-bc00-fc2683831e97',
        NinoCenter: "1f999e9d-9702-442d-87c0-3867a7d11f71"
    };

    public static ResourceTypeFee = {
        FeeSchool: "ca60d855-0408-4b98-a299-17151986025c",
        FeeLate: "bcddd58c-717c-4e10-8cc7-eae52aba4f3f",
        FeeService: "13acd369-7500-4442-83fb-e8d91e23dba7",
        FeeOptional: "cb626779-2dba-4d2e-8585-0670fb1b466f",
    }

    public static StatusClass = {
        EnumCode: "STATUS_CHILD_IN_CLASS",
        Enable: "c9215b27-150b-4d53-9710-001627cd768f",
        Disable: "71365878-d884-45e5-8e2e-defba87b971e",
        Finish: "323f044e-7cc7-4278-8e11-d0765167c9e8",
        Transfer: "61b2280d-8f36-40d1-9486-7435e55a7031",
        StayInHere: "ecae74a0-b1f8-4f18-a7fa-e0b980892580",
        UptoClass: "307578b8-7d6e-46d1-8e0a-0e66de56c287",
    }

    public static RoleUserCode = {
        Sale: "SALE"
    }

    public static PermissionCode = {
        View: "VIEW",
        Add: "ADD",
        Edit: "EDIT",
        Delete: "DELETE",
    }

    public static FeedbackType = {
        Code: "TYPE_FEEDBACK",
        TypeAppCode: "APP_FEEDBACK",
        TypeSchoolCode: "SCHOOL_FEEDBACK"
    }

    public static Relationship = {
        Father: "169a1974-fd21-4988-a5f3-0692034298dd",
        Mom: "e9c74b6a-0c7d-4af2-9530-0b5f054723b5"
    }

    public static InvoiceType = {
        Fee: "37513c2c-a4e1-47ae-8f25-f90773cf3ff0",
        Other: "0aa6fc8c-addd-4175-81aa-fb5cf5d9e5f0"
    }

    public static CourseRegistrationStatus = {
        TRANSFERRED: "621cf87d-5508-49e1-8055-69a067b55978",
        ACTIVE: "fa80eb79-7e5e-44f4-9a38-b92366db37ec",
        FINISH: "0dda6e76-e9dd-4e7e-8be0-d06caceb1024"
    }

    public static Sex = {
        Male: "686b303d-d81c-476a-a702-a4ffab5c2b1a",
        Female: "20bca445-ca81-4c98-bf11-e68bac64a6ef"
    }

    public static LocationChildRegistrationType = {
        New: "Đăng ký mới",
        Change: "Đăng ký thay đổi"
    }

    public static ConversationType = {
        SchoolToParent: "8def9781-4a8c-431e-bb26-8b5e3ab8b1a2",
        SchoolToTeacher: "6960d538-5242-42aa-91ed-f21a7dd75395"
    };

    public static UrlDefault = {
        Avatar: "https://api2019v1nino.ninoapp.vn/images/avatar-default.png",
    };
}