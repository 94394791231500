import { Injectable } from '@angular/core';

@Injectable()
export class FileHelper {

    constructor() { }

    static bytesToSize(bytes: number) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';

        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());
        return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
    };
}