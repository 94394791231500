export interface AppSetting {

}

export class AppSetting implements AppSetting {
    constructor() {
        this.setDefault();
    }

    private setDefault() {

    }
}