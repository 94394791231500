
import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class DoSchoolApiService extends BaseService {

    constructor(public apiService: ApiService) {
        super(apiService, 'DoSchoolApi');
    }

    getMobileRoles() {
        return this.apiService.get(`${this.apiName}/GetMobileRoles`)
    }
    
    getRoleBySchools(id: string) {
        return this.apiService.get(`${this.apiName}/GetRoleBySchools/${id}`)
    }

    getConfigRole() {
        return this.apiService.get(`${this.apiName}/GetConfigRole`)
    }

    getAllSystemModule() {
        return this.apiService.get(`${this.apiName}/GetAllSystemModule`)
    }
}
