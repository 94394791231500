
import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class DoHealthIndexOfChildrenApiService extends BaseService {

    constructor(public apiService: ApiService) {
        super(apiService, 'DoHealthIndexOfChildrenApi');
    }
    public getByChildAndMonth(childId: string, month: number, year: number) {
        return this.apiService.get(
            `${this.apiName}/GetByChildAndMonth?childId=${childId}&month=${month}&year=${year}`
        );
    }
}
