import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { merge } from "rxjs";
import { filter, map, mergeMap } from "rxjs/operators";
import { environment } from "../environments/environment";
import { Logger } from "./core/logger/logger.service";
import { HeaderService, I18nService } from "./core/services";
import { AuthService } from "./infrastructure";
import { HeaderInfoModel } from "./infrastructure/models";


const log = new Logger("App");

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private headerService: HeaderService,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private authService: AuthService
  ) { 
    // console.log(authService.currentUser);
    
  }

  ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    log.debug("init");

    // Setup translations
    this.i18nService.init(
      environment.defaultLanguage,
      environment.supportedLanguages
    );
    // this.i18nService.language = 'vi-VN';

    const onNavigationEnd = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === "primary"),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        const title = event["title"];
        const subtitle = event["subtitle"];
        const content = event["content"] || "n";
        if (title) {
          let headerInfo: HeaderInfoModel = {
            title,
            subtitle,
            content: this.translateService.instant(
              content
            )
          }
            ;
          this.headerService.setHeaderInfo(headerInfo);
        }
      });
  }
}
