import { Injectable } from "@angular/core";

import { ApiService } from "../../core/services";
import { BaseService } from "../../core/services/base.service";

@Injectable()
export class DoInvoiceApiService extends BaseService {
  constructor(public apiService: ApiService) {
    super(apiService, "DoInvoiceApi");
  }
  addInvoiceFromReceipt(model: any) {
    return this.apiService.post(`${this.apiName}/AddInvoiceFromReceipt`, model);
  }

  getInvoiceByChild(id: string) {
    return this.apiService.get(`${this.apiName}/GetInvoiceByChild?childId=${id}`);
  }

  getReceiptUnPaidByChild(id: string) {
    return this.apiService.get(`${this.apiName}/GetReceiptUnPaidByChild?childId=${id}`);
  }
  lockEdit(id: string) {
    return this.apiService.get(`${this.apiName}/LockEdit/${id}`)
  }
}
