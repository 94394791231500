
import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class DoSchoolStartEndDayInWeekApiService extends BaseService {

    constructor(public apiService: ApiService) {
        super(apiService, 'DoSchoolStartEndDayInWeekApi');
    }

    getByCurrentSchool() {
        return this.apiService.get(`${this.apiName}/GetByCurrentSchool`);
    }
}
