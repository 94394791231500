
import { Injectable } from '@angular/core';

import { ApiService } from '../../core/services';
import { BaseService } from '../../core/services/base.service';


@Injectable()
export class DoMenuFoodApiService extends BaseService {

    constructor(public apiService: ApiService) {
        super(apiService, 'DoMenuFoodApi');
    }

    public addFood(model: any) {
        return this.apiService.post(`${this.apiName}/AddFood`, model);
    }

    public updateFood(model: any) {
        return this.apiService.post(`${this.apiName}/Update`, model);
    }

    public getFoods() {
        return this.apiService.get(`${this.apiName}/GetFoods`);
    }

    public sendNotification(model: any) {
        return this.apiService.post(`${this.apiName}/SendNotification`, model);
    }

    public importMenuFood(data: any) {
      return this.apiService.post(`${this.apiName}/ImportMenuFood`, data);
  }
}
